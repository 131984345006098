import React, { Component } from 'react';
import IosSwitch from '../IosSwitch';
import DefaultButton from '../DefaultButton';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../helper/SessionHelper';
import ReportHelper from '../../helper/ReportHelper';
import moment from "moment-timezone";
import Firestore from '../../api/firebase/Firestore';
import { toast } from 'react-toastify';
import ReportDefaultModalOptions from './ReportDefaultModalOptions';
import CurrencyHelper from '../../helper/CurrencyHelper';
import PaymentMethodHelper from '../../helper/PaymentMethodHelper';

export default class ExpenseInPeriodReport extends Component {

    state = {
        loadingModal: true,
        adminDocs: [],
        admins: [],
        providerDocs: [],
        providers: [],
        allAdmins: true,
        allProviders: true,
        showBuying: false,
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        output: 'download',
        format: 'pdf',
    }

    async componentDidMount() {

        if (this.props.getDocs && this.props.getDocs) {

            for (let index = 0; index < this.props.getDocs.length; index++) {
                
                const getDoc = this.props.getDocs[index];
                await getDoc(this);
            }
        }

        this.setState({ loadingModal: false });
    }

    async getReportDocs() {

        let docs = [];

        let start = moment(this.state.start).startOf('day').toDate();
        let end = moment(this.state.end).endOf('day').toDate();
        let inventoryMovements = await Firestore.customQuery('expense').where('id_company', '==', SessionHelper.getData().id_company).where('created_on', '>=', start).where('created_on', '<=', end).orderBy('created_on', 'desc').get();

        let providers = this.state.allProviders ? this.state.providerDocs.map((provider) => { return provider.id }) : this.state.providers;
        let admins = this.state.allAdmins ? this.state.adminDocs.map((admin) => { return admin.id }) : this.state.admins;

        inventoryMovements.forEach((expenseDoc, key) => {

            let expense = { ...expenseDoc.data(), id: expenseDoc.id };

            if ((providers.includes(expense.provider) || !expense.provider) && (this.state.allAdmins || admins.includes(expense.created_by)) && (!this.state.showBuying || (this.state.showBuying && expense.buying))) {

                let provider = this.state.providerDocs.find(item => item.id === expense.provider);
                let user = '';

                if (expense.created_by) {

                    let userDoc = this.state.adminDocs.find(item => item.id === expense.created_by);

                    if (userDoc) {

                        user = userDoc.name;

                    } else {

                        user = 'Usuário Desconhecido';
                    }
                }

                docs.push({
                    ...expense,
                    user: user,
                    provider: provider ? provider.name : '',
                })
            }
        });

        return docs;
    }

    async print() {

        if (this.state.start && this.state.end) {

            this.setState({ loadingModal: true });

            let docs = await this.getReportDocs();
            let name = `Despesas - ${SessionHelper.getData().company.name}`;
            let subtitle = `De ${moment(this.state.start).format('DD/MM/YYYY')} até ${moment(this.state.end).format('DD/MM/YYYY')}`;
            let report = await ReportHelper.createReport(name, 5, subtitle, this.state.format);

            report = await ReportHelper.createColumns(report, docs, [
                { name: 'Descrição', width: 13, getText: (doc) => this.state.format === 'pdf' ? ReportHelper.cutName(doc.name, 32) : doc.name },
                { name: 'Valor',width: 78, getText: (doc) => CurrencyHelper.centsToMoney(doc.amount) },
                { name: 'Método de Pag.', width: 102, getText: (doc) => doc.payment_method ? PaymentMethodHelper.getPaymentMethodLabel(doc.payment_method) : "" },
                { name: 'Fornecedor', width: 133, getText: (doc) => doc.provider ? this.state.format === 'pdf' ? ReportHelper.cutName(doc.provider, 16) : doc.provider : '' },
                { name: 'Data Lançamento', width: 167, getText: (doc) => doc.date ? moment(doc.date.toDate ? doc.date.toDate() : doc.date).format('DD/MM/YYYY HH:mm') : "" },
            ]);
    
            ReportHelper.print(report, this.state.output, this.state.format);

            this.setState({ loadingModal: false });

        } else {

            toast.warn('Preencha o período do relatório');
        }
    }

    renderLoading() {

        return (

            <div style={{ height: 200 }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderModal() {

        return (

            <div>
                <IosSwitch fullWidth label={'Todos os Fornecedores'} checked={this.state.allProviders} onChange={(e) => { this.setState({ allProviders: e, providers: [] }) }} />
                { !this.state.allProviders ? ReportHelper.renderProviderSelection(this, this.state.providerDocs, 'providers') : null }

                <IosSwitch fullWidth label={'Todos os Administradores'} checked={this.state.allAdmins} onChange={(e) => { this.setState({ allAdmins: e, admins: [] }) }} />
                { !this.state.allAdmins ? ReportHelper.renderAdminSelection(this, this.state.adminDocs, 'admins') : null }

                <IosSwitch fullWidth label={'Apenas Compras'} checked={this.state.showBuying} onChange={(e) => { this.setState({ showBuying: e }) }} />

                <div style={{ marginTop: 15 }}/>

                { ReportHelper.renderPeriodSelection(this, 'start', 'end') }

                <ReportDefaultModalOptions format={this.state.format} output={this.state.output} onOutputChange={(value) => { this.setState({ output: value }) }} onFormatChange={(value) => { this.setState({ format: value }) }}/>
                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <DefaultButton width={'100%'} title={'Imprimir'} onClick={() => { this.print() }} />
                </div>
            </div>
        )
    }

    render() {

        return (

            this.state.loadingModal ? this.renderLoading() : this.renderModal()
        )
    }
}